if ($("#nd-code").length > 0) {
  $(".nd-container").on("click", function () {
    $("#nd-code").css("display", "none");
    $("#nd-input").css("display", "");
    $("#nd-input").focus();
    $("#nd-code").data("old-value",$("#nd-code").html());
    
    $("#nd-input").on("input", () => {
      var value = $("#nd-input").val();
      $("#nd-code").html(value);
      $("#nd-input").data("input-event", true);

      if (value.length === 4) {
        setTimeout(function () {
          $("#nd-input").data("input-event", null);
          $("#nd-input").blur();
        }, 1000);
      }
    });
  });

  $("#nd-input").blur(function () {
    $("#nd-code").css("display", "");
    $("#nd-input").css("display", "none");

    if ($("#nd-input").data("input-event")) {
      return;
    }

    $.ajax({
      url: $(location).attr("href").replace("show", "edit"),
      type: "PUT",
      data: {
        number: $(this).val(),
      },
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
      },
      error: function (error) {
        $("#nd-input").val($("#nd-code").data('old-value'));
        $("#nd-code").html($("#nd-code").data('old-value'));
        swal({
            title: "Atenção",
            text: error.responseJSON.number[0],
            icon: "warning",
            confirmButtonText: "Ok",
        });
      },
      success: function (result) {
        var newNumber = pad(result.number, 4);
        $("#nd-code").html(newNumber);
        $("#nd-code").data('old-value', newNumber);
      },
    });
  });

  function pad(value, length) {
    return value.toString().length < length ? pad("0" + value, length) : value;
  }
}

$(document).ready(function () {
  $("a[data-href]").bind("click", function () {
    var url = new URL($(this).attr("data-href"));
    var path = url.pathname.split("/");
    var urlSecond = url.origin + "/images/invoices/" + path[path.length - 1];
  
    $.ajax({
      async: false,
      type: "GET",
      url: url.href,
      dataType: "json",
      error: function (err) {
        console.log(err);
        if (err.status == 200) {
          if (
            url.href.indexOf(".png") > -1 ||
            url.href.indexOf(".jpg") > -1 ||
            url.href.indexOf(".jpeg") > -1
          ) {
            window.open("/see/image?img=" + url.href);
          } else {
            window.open(url.href);
          }
        } else {
          $.ajax({
            async: false,
            type: "GET",
            url: urlSecond,
            dataType: "json",
            error: function () {
              if (
                urlSecond.indexOf(".png") > -1 ||
                urlSecond.indexOf(".jpg") > -1 ||
                urlSecond.indexOf(".jpeg") > -1
              ) {
                window.open("/see/image?img=" + urlSecond);
              } else {
                window.open(urlSecond);
              }
            },
          });
        }
      },
    });
  });
});
